'use client'

import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  CartItemFragment,
  CartQuery,
  CurrencyEnum,
  useCartQuery,
  useCustomerCartIdQuery,
} from '@/api'
import { StoreConfigType } from '@/common/types'
import { useAddToCart } from './hooks'
import { gtmTracker } from '@/common/services/tracking/google/gtm-tracker'
import { useAuthContext } from '../auth'
import { queryClient } from '@/common/services'
import { AddToCartParams } from './utils/add-to-cart.utils'
import { removeCookie } from '@/common/utils/cookie-utils'
import { consoleError, consoleLog } from '@/common/utils/console'

export type AddToCartTrackingConfig = {
  skipTracking?: boolean
}

type CartContext = {
  hasItems: boolean
  cartItems: CartItemFragment[]
  isCartFetchError: boolean
  isCartRefetching: boolean
  isAddingToCart: boolean
  addToCart?: (
    data: Partial<AddToCartParams>,
    trackingConfig?: AddToCartTrackingConfig,
  ) => Promise<void>
}

export const CartContext = createContext<CartContext>({} as CartContext)

type CartContextProviderProps = {
  storeConfig: StoreConfigType
  // cartId?: string
}

const fetchCartData = async (
  cartId: string,
  setFn: (data: CartQuery) => void,
) => {
  try {
    const data = await queryClient.fetchQuery({
      queryKey: useCartQuery.getKey({ cartId: `${cartId}-${Date.now()}` }),
      queryFn: useCartQuery.fetcher({ cartId }),
      retry: 5,
      retryDelay: 1000,
    })

    if (data.errors && !data.cart) {
      consoleError('error', { error: data.errors, data: null })
      return
    }

    if (data.cart) {
      setFn(data)
    }
  } catch (err) {
    consoleError(err)
  }
}

/**
 * Provider that handles the cart
 * @param storeConfig
 * @param children
 * @constructor
 */
export const CartContextProvider = ({
  storeConfig,
  children,
}: // cartId,
PropsWithChildren<CartContextProviderProps>) => {
  const { customerToken, guestToken } = useAuthContext()
  const { isAddingToCart, addToCart, isCartFetchError } = useAddToCart()

  const defaultCartId = guestToken ?? ''

  /**
   * cartId is sent from server with undefined value only when website is
   * visiting by guest
   */
  const [currCartId, setCurrCartId] = useState(defaultCartId)
  const [cartData, setCartData] = useState<CartQuery>()
  const [isCartRefetching, setIsCartRefetching] = useState(false)

  const fetchCartId = useCallback(async () => {
    try {
      if (!customerToken) {
        setCurrCartId(defaultCartId)
      }

      const response = await queryClient.fetchQuery({
        queryKey: [...useCustomerCartIdQuery.getKey(), customerToken],
        queryFn: useCustomerCartIdQuery.fetcher(),
        retry: 3,
        retryDelay: 1000,
      })

      if (!response.customerCart || response.errors) {
        throw new Error('Error while fetching cart id')
      }

      setCurrCartId(response.customerCart?.id ?? defaultCartId)
    } catch (err) {
      console.error({
        place: 'fetchCartIdError',
        err,
      })

      removeCookie('customerToken')
      removeCookie('customerSegment')
    }
  }, [customerToken, defaultCartId])

  useEffect(() => {
    fetchCartId()
  }, [fetchCartId])

  // TODO: implement notification for user to know that there was a problem to load cart data from the backend
  useEffect(() => {
    if (currCartId) {
      fetchCartData(currCartId, (cart) => {
        setCartData(cart)
      })
    }
  }, [currCartId])

  consoleLog(
    JSON.stringify({
      place: 'cart',
      customerToken,
      guestToken,
      cartId: currCartId,
    }),
  )

  const cartItems = useMemo(
    () =>
      ((cartData?.cart?.items || []).filter(Boolean) ??
        []) as CartItemFragment[],
    [cartData?.cart?.items],
  )

  const handleAddToCartTracking = useCallback(
    ({
      brand,
      quantity,
      productName,
      sku,
      parentSku,
      price,
      category,
      prodlistPlacement,
      productIds,
      productPosition,
    }: AddToCartParams) => {
      gtmTracker.trackAddProductToCart(
        {
          id: parentSku ? Number(parentSku) : Number(sku),
          brand: brand ?? '',
          name: productName,
          category: category ?? '',
        },
        quantity,
        price ?? 0,
        (storeConfig.currency as CurrencyEnum) ?? CurrencyEnum.Eur,
        {
          prodlistPlacement,
          productIds,
          productPosition,
        },
      )
    },
    [storeConfig.currency],
  )

  const addToCartWithCartId = useCallback(
    async (
      {
        brand,
        category,
        parentSku,
        productName,
        sku,
        price,
        quantity,
        isGiftCard,
        selectedOptions,
        prodlistPlacement,
        productIds,
        onSuccess,
        productPosition,
      }: Partial<AddToCartParams>,
      trackingConfig?: AddToCartTrackingConfig,
    ) => {
      setIsCartRefetching(true)
      let latestCartId = currCartId
      const productInfo: AddToCartParams = {
        productName: productName ?? '',
        parentSku,
        sku: sku ?? '',
        quantity: quantity ?? 1,
        brand,
        price,
        category,
        selectedOptions,
        isGiftCard,
      }

      await addToCart({
        cartId: currCartId,
        refreshTokens: (token) => {
          latestCartId = token
          setCurrCartId(token)
        },
        onSuccess: () => {
          onSuccess?.()

          if (trackingConfig?.skipTracking) {
            return
          }

          handleAddToCartTracking({
            ...productInfo,
            prodlistPlacement,
            productIds,
            productPosition,
          })
        },
        ...productInfo,
      })

      fetchCartData(latestCartId, (cart) => {
        setCartData(cart)
        setIsCartRefetching(false)
      })
    },
    [currCartId, addToCart, handleAddToCartTracking],
  )

  const contextValue = useMemo(
    () => ({
      cartItems,
      isAddingToCart,
      isCartRefetching,
      isCartFetchError,
      hasItems: cartItems.length > 0,
      addToCart: addToCartWithCartId,
    }),
    [
      cartItems,
      isAddingToCart,
      isCartFetchError,
      isCartRefetching,
      addToCartWithCartId,
    ],
  )

  return (
    <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
  )
}

export const useCartContext = () => useContext(CartContext)
